<template>
  <div class="body_bg">
    <v-snackbar
        timeout="2000"
        :value="snackbarShow"
        color="#F44336"
        absolute
        top
        v-model="snackbarShow"
    >{{snackbarText}}
    </v-snackbar>
    <div class="container">
      <div class="left">
        <img src="~assets/img/home/login_01.png">
      </div>
      <div class="right">
        <div class="r_title">
          <v-icon large color="#009688">mdi-home-city-outline</v-icon>
          <div class="card_title">房屋安全管理系统</div>
        </div>
        <div class="r_input">
          <!--账号 -->
          <v-text-field
              v-model="loginMsg.username"
              label="账号"
              placeholder="请输入..."
              filled
              rounded
              dense
              :rules="[v => !!v || '不能为空！']"
          ></v-text-field>
          <!--密码-->
          <v-text-field
              v-model="loginMsg.password"
              label="密码"
              placeholder="请输入..."
              filled
              rounded
              dense
              :type="show ? 'text' : 'password'"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
              :rules="[v => !!v || '不能为空！']"
          ></v-text-field>
          <div class="r_input_text">
            <v-checkbox
                v-model="checkbox"
                color="#009688"
                @click="showCheckbox"
            >
              <template slot="label">
                <div style="font-size: 13px">7天免登录</div>
              </template>
            </v-checkbox>
            <div style="color: #55a532;font-size: 13px">忘记密码？</div>
          </div>
        </div>
        <div class="r_bt text-center">
          <v-btn
              depressed
              color="#97D1B6"
              rounded
              width="88%"
              height="40px"
              dark
              style="font-weight: bold;font-size: 16px"
              @click="login"
          >登录</v-btn>
        </div>
        <div class="r_registered">
          没有账户？
          <span style="color:#55a532" @click="toSignUp">注册</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {userLogin} from "../../network/Details/home";

export default {
  name: "Login",
  data(){
    return{
      checkbox:false,
      //密码显示
      show:false,
      //账号
      loginMsg:{
        username:'',
        password:'',
        status:0
      },
      //提示
      snackbarShow:false,
      snackbarText:'',
    }
  },
  methods:{
    showCheckbox(){
      this.loginMsg.status = this.checkbox === true ? 1 : 0;
    },
    //登录
    login(){
      if(this.loginMsg.username.length == 0 || this.loginMsg.password == 0){
        this.snackbarText = '账号或密码不能为空！';
        this.snackbarShow = true;
        return
      }
      userLogin(this.loginMsg).then(res=>{
        if(res.code === 200){
          window.localStorage.setItem('username',this.loginMsg.username);
          this.$router.push('/home');
        }else {
          this.snackbarText = res.message;
          this.snackbarShow = true;
        }
      })
    },
    //跳转到注册
    toSignUp(){
      this.$router.push('/signup')
    }
  }
}
</script>

<style lang="less" scoped>
.body_bg{
  position:absolute;
  left:0;
  top:0px;
  width:100%;
  height:100%;
  //background-image: linear-gradient(to right, #c2e59c , #64b3f4 );
  background-image: linear-gradient(-225deg, #D4FFEC 0%, #57F2CC 48%, #4596FB 100%);

}
.container{
  width: 691px;
  height: 411px;
  border-radius: 21px;
  background: #ffffff;
  box-shadow: 3px 2px 8px rgba(0,0,0,0.31);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  padding: 15px;
  display: flex;
  flex-direction: row;
  .left{
  }
  .right{
    height: 365px;
    width: 340px;
    border: #F6F6F6 1px solid;
    display: flex;
    flex-direction: column;
    .r_title{
      height: 60px;
      display: flex;
      flex-direction: row;
      font-size: 18px;
      text-align: center;
      line-height: 60px;
      font-weight: bold;
      justify-content: space-evenly;
    }
    .r_input{
      margin-top: 20px;
      width: 90%;
      margin-left: 6%;
      .r_input_text{
        margin-top: 5px;
        width: 90%;
        height: 18px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-left: 5%;
      }
    }
    .r_bt{
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .r_registered{
      margin-top: 10px;
      text-align: center;
      color: #666666;
      font-size: 13px;
    }

  }
}
</style>